import { Box, Heading, Text, useBreakpointValue } from '@chakra-ui/react';
import * as React from 'react';
import corner from '../../../assets/images/frame/corner.jpg';
import horizontal from '../../../assets/images/frame/frame_horizontal.png';

interface ComponentProps {
  name: string;
  courses: Array<ICourse>;
}

interface ICourse{
  name: string,
  description: string
}

const Menu = ({ name, courses }: ComponentProps) => {
  return (
    <Box className="body dark-background">
      <Box className="outer-border">
        <Box className="">
          <Box className="inner-border" px="1">
            <img
              className="corner-decoration corner-left-top"
              src={corner}
              alt=""
            ></img>
            <img
              className="corner-decoration corner-right-top"
              src={corner}
              alt=""
            ></img>
            <img
              className="corner-decoration corner-right-bottom"
              src={corner}
              alt=""
            ></img>
            <img
              className="corner-decoration corner-left-bottom"
              src={corner}
              alt=""
            ></img>
            <img
              className="vertical-decoration top"
              src={horizontal}
              alt=""
            ></img>
            <img
              className="vertical-decoration bottom"
              src={horizontal}
              alt=""
            ></img>

            <Box className="container" my="20">
              <Box className="row">
                <Box className="col-lg-12 text-center" pb="20">
                  <Text
                    width="full"
                    textAlign="center"
                    fontFamily="playfair display"
                    lineHeight={1.2}
                    my="4"
                    mt="6"
                    fontSize={useBreakpointValue({
                      base: '5xl',
                      md: '5xl',
                    })}
                    fontWeight="100"
                    mb="16"
                  >
                    {name}
                  </Text>
                  {courses.map((course:any, i) => {
                    return (
                      <div key={i}>
                        <CustomHeading>{course.name} {course.price && <span style={{fontSize:".7em"}}>(${course.price} pp) </span>}</CustomHeading>
                        <Text whiteSpace="pre-line">{course.description}</Text>
                      </div>
                    );
                  })}

                  {/* <CustomHeading>Hors d’oeurves</CustomHeading>
                  <Text>Samosas (beef, chicken, veg)</Text>
                  <Text>
                    Chicken/Veg spring roll served with Sweet chilli sauce
                  </Text>
                  <Text>Succulent chicken wings</Text>
                  <Text>Crispy on the outside and succulent on the inside</Text>
                  <Text>Salad bar</Text>
                  <Text>Coleslaw salad</Text>
                  <Text>Garden Salad</Text>
                  <Text>
                    Lettuce, peppers, cherry tomatoes, cucumber with an option
                    of feta cheese or olives and a dressing
                  </Text>
                  <CustomHeading>Main</CustomHeading>
                  <Text>Beef Stew</Text>
                  <Text>
                    A basic beef stew with vegetables that cooks to perfection
                    every time.
                  </Text>
                  <Text>Marinated roasted/grilled chicken</Text>
                  <Text>
                    Choose your favourite marinade and sauce for the chicken
                    thighs and drumstick
                  </Text>
                  <CustomHeading>Starches</CustomHeading>
                  <Text>Roast potatoes wedges</Text>
                  <Text>Basmati rice</Text>
                  <Text>Sadza</Text>
                  <Text>Vegetables</Text>
                  <Text>Sautéed medley of vegetables in season</Text>

                  <Text>Butternut in cinnamon and butter sauce</Text>
                  <CustomHeading>Dessert</CustomHeading>
                  <Text>Fruit cocktail served with vanilla ice cream</Text> */}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const CustomHeading = ({ children }: {children:React.ReactNode}) => {
  return (
    <Heading
      fontFamily="playfair display"
      as="h3"
      textDecoration="underline"
      fontWeight="500"
      fontSize="3xl"
      mb="4"
      mt="8"
      fontStyle="bold"
    >
      {children}
    </Heading>
  );
};

export default Menu;
