import { Flex, Heading, Stack, Text, VStack } from '@chakra-ui/layout';
import * as React from 'react';
import { heroImage } from '../common/Assets';

const Header = ({ title, subtitle,backgroundImage = heroImage}: { title: string; subtitle: string,backgroundImage?: string }) => {
  return (
    <Flex
      w={'full'}
      h={'0vh'}
      minH={{ base: '200px', md: '400px' }}
      backgroundImage={`linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),url(${backgroundImage})`}
      backgroundSize={'cover'}
      backgroundRepeat="no-repeat"
      backgroundPosition={'center center'}
    >
      <VStack

        w={'full'}
        justify={'center'}
        px={{ base: 4, md: 8 }}
        bgGradient={'linear(to-r, blackAlpha.600, transparent)'}
      >
        <Stack maxW={'4xl'} align={'flex-start'} spacing={6} textAlign="center">
          <Heading
            as="h1"
            width="full"
            color={'white'}
            fontWeight={200}
            lineHeight={1.2}
            textAlign="center"
            textStyle="heading"
            fontFamily="Playfair Display"
            fontSize={{ base: '3xl', md: '6xl' }}
          >
            {title}
          </Heading>
          <Text
            width="full"
            textAlign="center"
            color={'white'}
            fontFamily="Playfair Display"
            lineHeight={1.2}
            fontSize={{ base: '2xl', md: '3xl' }}
          >
            {subtitle}
          </Text>
        </Stack>
      </VStack>
    </Flex>
  );
};

export { Header };
