import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from '@chakra-ui/react';
import * as Yup from 'yup';
import { Field, FieldProps, Form, Formik} from 'formik';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import bass_bg from '../../assets/images/hero_banner.jpg';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { loginUser, setCurrentUser } from '../../services/auth.service';
import * as React from 'react';

const FormSchema = Yup.object().shape({
  email: Yup.string().email().required('Required'),
  password: Yup.string()
    .min(2, 'Too Short!')
    .max(70, 'Too Long!')
    .required('Required'),
});

interface FormData {
  email: string | undefined;
  password: string | undefined;
  remember: boolean | undefined;
}

export const LoginPage = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  // get history from router
  const history = useHistory();

  async function submit(data: FormData) {
    setLoading(true);
    await loginUser(data)
      .then(response => {
        setCurrentUser(response?.data?.data?.token, data?.remember);
        // redirect to admin panel if login successful
        history.push('/admin/dashboard');
      })
      .catch(error => {
        console.log(error.response);
        toast.error('Login Failed');
      });
    setLoading(false);
  }

  function toggleShow() {
    setShowPassword(!showPassword);
  }
  return (
    <Box>
      <Flex
        maxW={{ base: '100%', md: '60%',lg:'50%' }}
        borderWidth="0px"
        borderRadius="lg"
        boxShadow="lg"
        overflow="hidden"
        mx={{ base: '4', md: 'auto' }}
        mt={{base:"16",md:"20vh"}}
        wrap="wrap"
        bg="green.50"
        alignItems="stretch"
      >
        <Box
          width={{ base: '100%', md: '50%' }}
          style={{
            background: `url(${bass_bg})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }}
        ></Box>
        <Box
          width={{ base: '100%', md: '50%' }}
          mt="8"
          mb="4"
          px={{ base: '4', md: '4' }}
        >
          <Text fontSize="3xl">Sign In</Text>
          <Text color="gray.600" fontSize="sm">
            Enter Your Details Below
          </Text>
          <Formik
            initialValues={{ email: '', password: '', remember: false }}
            validationSchema={FormSchema}
            onSubmit={values => {
              submit(values);
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <Field name="email">
                  {({ field, form }:FieldProps<any>) => (
                    <FormControl
                      isInvalid={!!form.errors.email && !!form.touched.email}
                    >
                      <Input
                        {...field}
                        mt="8"
                        id="email"
                        type="email"
                        placeholder="email"
                        _autofill={{
                          transition: 'all 5000s ease-in-out 0s',
                        }}
                      />
                      <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>

                <Field name="password">
                  {({ field, form }:FieldProps<any>) => (
                    <FormControl
                      isInvalid={!!form.errors.password && !!form.touched.password}
                    >
                      <InputGroup size="md" mt="6">
                        <Input
                          {...field}
                          pr="4.5rem"
                          type={showPassword ? 'text' : 'password'}
                          placeholder="Enter password"
                          _autofill={{
                            transition: 'all 5000s ease-in-out 0s',
                          }}
                        />
                        <InputRightElement width="4.5rem">
                          <IconButton
                            aria-label={'toggle password visibility'}
                            variant="ghost"
                            onClick={toggleShow}
                            _hover={{
                              bg: 'none',
                            }}
                            _active={{
                              bg: 'none',
                              outline: 'none',
                            }}
                            _focus={{
                              outline: 'none',
                            }}
                            icon={showPassword ? <FiEyeOff /> : <FiEye />}
                          />
                        </InputRightElement>
                      </InputGroup>
                      <FormErrorMessage>
                        {form.errors.password}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="remember">
                  {({ field, form }:FieldProps<any>) => (
                    <Checkbox {...field} mt="6">
                      Remember Me
                    </Checkbox>
                  )}
                </Field>
                <Button
                  colorScheme="blue"
                  w="full"
                  mt="6"
                  type="submit"
                  isLoading={loading}
                  loadingText="Submitting"
                >
                  Submit
                </Button>
              </Form>
            )}
          </Formik>
        </Box>
      </Flex>
    </Box>
  );
};
