import {
  Box,
  Button,
  Textarea,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Image,
  NumberInput,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInputField,
  NumberInputStepper,
  Input,
} from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import {
  getHireItem,
  updateHireItem,
  uploadHireItemImage,
} from '../../../../services/hireitems.service';
import Card from '../../../../components/Card';
import { Dropzone } from '../../../../components/Dropzone';
import { getImageURL } from '../../../../common/ImageHelpers';
import { image_placeholder } from '../../../../common/Placeholders';
import { BoxProps } from '@chakra-ui/react';

const FormSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
});

interface IHireItem {
  name: string;
  description: string,
  price: number,
  minimum: number,
  image: string | null;
}

interface ComponentProps extends BoxProps {
  id: string;
}

const EditHireItemCard = ({ id, ...other }: ComponentProps) => {
  const [loading, setLoading] = useState(false);
  const [hireitem, setHireItem] = useState<IHireItem>({
    name: '',
    description:'',
    price:1,
    minimum:1,
    image: null,
  });
  const [images, setImages] = useState();
  const [price, setPrice] = useState(hireitem.price);
  const [minimum, setMinimum] = useState(hireitem.minimum);
  const [key, setKey] = useState(1);

  const handleChange = (valuesAsString: string, valueAsNumber: number) =>
    setPrice(valueAsNumber);

  const handleMinimumChange = (valuesAsString: string, valueAsNumber: number) =>
    setMinimum(valueAsNumber);
 

  function getImages(images: any) {
    setImages(images);
  }

  function formatFormdata(data: any) {
    const formData = new FormData();
    formData.append('image', data ? data[0] : null);
    return formData;
  }

  interface MyFormValues {
    name: string;
    description: string;
    price: number;
    minimum: number;
  }
  const initialValues: MyFormValues = {
    name: hireitem.name,
    description: hireitem.description,
    price: 1,
    minimum: 1,
  };

  async function submit(data: any) {
    console.log( {...data, price: price },data);
    setLoading(true);
    var image = formatFormdata(images);
    // if (!images || typeof images[0] === 'undefined') {
    // if (!images) {
    //   toast.error('image required');
    //   setLoading(false);
    //   return;
    // }

    await updateHireItem(id, {...data,price: price,minimum:minimum})
      .then((response: any) => {
        setKey(key ? 0 : 1);
        toast.info('HireItem Updated');
        setPrice(response?.data?.data?.price);
        setMinimum(response?.data?.data?.minimum);
        setHireItem(response?.data?.data);
      })
      .catch((error: any) => {
        if (error?.response?.data?.data) {
          var errors = Object.values(error?.response?.data?.data);
          errors.forEach((value: any) => {
            toast.error(value[0]);
          });
        } else {
          toast.error('Error saving item');
        }
      });

    if (id && images) {
      await uploadHireItemImage(id, image)
        .then((response: any) => {
          setHireItem(response?.data?.data);
          toast.info('Image uploaded');
        })
        .catch((error: any) => {
          console.log(error);
          toast.error('error uploading image');
          setKey(key ? 0 : 1);
        });
    }

    

    setLoading(false);
  }

  //   setup form
  useEffect(() => {
    getHireItem(id)
      .then((response: any) => {
        console.log(response?.data?.data?.minimum)
        setHireItem(response?.data?.data);
        setPrice(response?.data?.data?.price);
        setMinimum(response?.data?.data?.minimum);
      })
      .catch((error: any) => {
        toast.error('error loading hireitem');
      });
  }, [id]);

  return (
    <Card
      width="100%"
      mb="4"
      px={{ base: '4', md: '4' }}
      borderRadius="md"
      shadow="md"
      py="4"
      key={key}
      {...other}
    >
      <Box>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={FormSchema}
          onSubmit={values => {
            submit(values);
          }}
        >
          {({ isSubmitting, values }) => (
            <Form>
              {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
              <Field name="name">
                {({ field, form }: { field: any; form: any }) => (
                  <FormControl
                    isInvalid={form.errors.name && form.touched.name}
                    isRequired
                    mt="8"
                  >
                    <FormLabel>Name</FormLabel>
                    <Input
                      {...field}
                      type="text"
                      placeholder="name"
                      _autofill={{
                        transition: 'all 5000s ease-in-out 0s',
                      }}
                    />
                    <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name="price">
                {({ field, form }: { field: any; form: any }) => (
                  <FormControl
                    isInvalid={form.errors.price && form.touched.price}
                    isRequired
                    mt="8"
                  >
                    <FormLabel>Price</FormLabel>
                    <NumberInput
                      defaultValue={1}
                      min={1}
                      value={price}
                      name="price"
                      id="price"
                      onChange={handleChange}
                    >
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                    <FormErrorMessage>{form.errors.price}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name="minimum">
                {({ field, form }: { field: any; form: any }) => (
                  <FormControl
                    isInvalid={form.errors.minimum && form.touched.minimum}
                    isRequired
                    mt="8"
                  >
                    <FormLabel>Minimum quantity</FormLabel>
                    <NumberInput
                      defaultValue={1}
                      min={1}
                      value={minimum}
                      name="minimum"
                      id="minimum"
                      onChange={handleMinimumChange}
                    >
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                    <FormErrorMessage>{form.errors.minimum}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name="description">
                {({ field, form }: { field: any; form: any }) => (
                  <FormControl
                    isInvalid={
                      form.errors.description && form.touched.description
                    }
                    mt="8"
                  >
                    <FormLabel>Description</FormLabel>
                    <Textarea
                      placeholder="description"
                      {...field}
                      type="description"
                      _autofill={{
                        transition: 'all 5000s ease-in-out 0s',
                      }}
                    />
                    <FormErrorMessage>
                      {form.errors.description}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              {hireitem?.image && (
                <Image
                  mt="8"
                  src={hireitem.image ? getImageURL(hireitem.image) : image_placeholder}
                  alt={hireitem.name}
                  fallbackSrc={image_placeholder}
                  width={{ base: '100%', md: '40%' }}
                  objectFit="cover"
                />
              )}
              <Dropzone mt="8" getImages={getImages}></Dropzone>

              <Button
                colorScheme="blue"
                // w="full"
                mt="6"
                type="submit"
                isLoading={loading}
                loadingText="Submitting"
              >
                Submit
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
    </Card>
  );
};

export default EditHireItemCard;
