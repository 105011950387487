import { Flex, Stack, Text, useColorModeValue } from '@chakra-ui/react';
import * as React from 'react';
import { Link } from 'react-router-dom';

interface ComponentProps {
  label: string;
  link: string;
  onClose: () => void;
}

const MobileNavItem = ({ label, link, onClose }: ComponentProps) => {
  return (
    <Stack spacing={4}>
      <Link to={link}>
        <Flex
          onClick={onClose}
          py={2}
          justify={'space-between'}
          align={'center'}
          _hover={{
            textDecoration: 'none',
          }}
        >
          <Text
            fontWeight={600}
            color={useColorModeValue('gray.600', 'gray.200')}
          >
            {label}
          </Text>
        </Flex>
      </Link>
    </Stack>
  );
};

export default MobileNavItem;
