import { createSlice } from '@reduxjs/toolkit';
import { IProduct } from '../interfaces/ProductInterfaces';

const initialState: Array<IProduct> = [];

export const shopProductsSlice = createSlice({
  name: 'shopproducts',
  initialState,
  reducers: {
    addItem: (
      state,
      action: {
        payload: { product: IProduct; quantity: number };
        type: string;
      }
    ) => {
      var exists = state
        .filter(product => {
          return product.id === action.payload.product.id;
        })
        .shift();

      // if does not exist create new item and assign the value
      if (!exists) {
        state.push({
          ...action.payload.product,
          quantity: action.payload.quantity,
        });
      }
      else{
        return state.map(item => {
          // console.log(item.quantity + action.payload.quantity);
          if (item.id !== action.payload.product.id) {
            return item;
          }
  
          return {
            ...item,
            // quantity: item.quantity + action.payload.quantity,
            quantity: action.payload.quantity,
          };
        });
      }

      return state
      // if exists increment

      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      // check if there is a similar item in state
      // var cartItem = state.cartItems
      //   .filter(item => {
      //     return item.id === action.payload.id;
      //   })
      //   .shift();
      // if (cartItem) {
      //   state.cartItems;
      // }
      // state.value += 1;
    },
    removeItem: (
      state,
      action: {
        payload: { product: IProduct };
        type: string;
      }
    ) => {
      return state.filter((product)=>{
        return product.id !== action.payload.product.id
      })
    },
    clearCart: state => {
      return initialState
    },
  },
});

// Action creators are generated for each case reducer function
export const { addItem, removeItem,clearCart} = shopProductsSlice.actions;

export default shopProductsSlice.reducer;
