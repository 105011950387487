import { Button } from "@chakra-ui/button";
import Icon from "@chakra-ui/icon";
import { Box, Flex, Spacer, Text } from "@chakra-ui/layout";
import { FiList } from "react-icons/fi";
import { Link } from "react-router-dom";
import { RouteNames } from "../../../../common/RouteNames";
import { Page } from "../../../../components/Page";
import CreateDeliveryOptionCard from "./CreateDeliveryOptionCard";

export const CreateDeliveryOptionsPage = ()=>{

    return (
      <Page title="add delivery option">
        <Box>
          <Flex>
            <Text fontSize="2xl" fontWeight="bold">
              Add Product 
            </Text>
            <Spacer />
            <Link to={`${RouteNames.admin.deliveryoptions}`}>
              <Button colorScheme="green" size="sm">
                <Icon as={FiList} mr="1" />
                Back to List
              </Button>
            </Link>
          </Flex>
          <CreateDeliveryOptionCard />
        </Box>
      </Page>
    );
}