import {
  Box,
  Flex,
  Heading,
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import threetotwo from '../../../../assets/images/placeholders/threetotwo.jpg';
import { useParams } from 'react-router-dom';
import { Page } from '../../../../components/Page';
import Menu from '../Menu';
import { useContext, useEffect, useState } from 'react';
import { getEventBySlug } from '../../../../services/events.service';
import { toast } from 'react-toastify';
import { Header } from '../../../../components/Header';
import { BannerContext } from '../../../../layouts/MainLayout';
import { getBannerImageByName } from '../../../../common/ImageHelpers';

interface IMenu {
  name: string;
  courses: any;
}

interface IEvent {
  name: string;
  subtitle: string;
  image: '';
}

export const EventMenus = () => {
  const [menus, setMenus] = useState([]);
  const banners = useContext(BannerContext)
  const [event, setEvent] = useState<IEvent>({
    name: '',
    subtitle: '',
    image: '',
  });
  let { menu_slug }: { menu_slug: any } = useParams();

  useEffect(() => {
    getEventBySlug(menu_slug)
      .then((response: any) => {
        console.log(response?.data?.data);
        setMenus(response?.data?.data?.menus);
        setEvent(response?.data?.data);
      })
      .catch(error => {
        toast.error('Loading Error');
      });
  }, [menu_slug]);
  return (
    <Page title="Amazing Catering in Harare">
      <Box>
        <Header title={event.name} subtitle={event.subtitle} backgroundImage={getBannerImageByName('eventmenuspagebanner',banners)}/>
        <Text
          width="full"
          textAlign="center"
          fontFamily="playfair display"
          lineHeight={1.2}
          my="8"
          mt="12"
          fontSize={useBreakpointValue({ base: '2xl', md: '5xl' })}
          fontWeight="100"
        >
          Browse through our Menus
        </Text>
        <Tabs
          mt="8"
          align="center"
          color="gray.400"
          mx={{ base: '2', md: '8' }}
          colorScheme="orange"
        >
          <TabList borderColor="gray.200" mb="10" fontFamily="playfair display">
            {menus.map((menu: IMenu, i) => {
              return (
                <Tab key={i} fontSize="lg">
                  {menu.name}
                </Tab>
              );
            })}
          </TabList>

          <TabPanels>
            {menus.map((menu: IMenu, i) => {
              return (
                <TabPanel key={i} style={{ background: '#3C3D3F' }}>
                  <Menu name={menu.name} courses={menu.courses} />
                </TabPanel>
              );
            })}
          </TabPanels>
        </Tabs>
        <Box
          mt="20"
          style={{
            background: `url(${threetotwo})`,
          }}
          backgroundSize="cover"
          bgRepeat="no-repeat"
        >
          <Flex opacity=".8">
            <Spacer></Spacer>
            <Box
              w={{ base: '100%', md: '50%' }}
              my="10"
              bg="white"
              color="gray.900"
              px="10"
              py="10"
            >
              <Heading
                fontFamily="Playfair Display"
                fontWeight="200"
                fontSize="5xl"
              >
                Lovingly Made <br /> Perfectly Delivered
              </Heading>
              <Text mt="6">
                Cater4u was born out of a passionate love for food - the
                ingredients; the aroma and flavours; the flair and creativity of
                presentation and the absolute joy and warmth that food brings to
                any gathering.
              </Text>
              <Text mt="6">
                At Cater4u, we are so passionate about our food that we hold
                ourselves to exceptionally high standards that focus on giving
                you individualised flawless service at every touch point - we
                CATER4U!
              </Text>
              <Text mt="6">
                For us, good food evokes warm feelings of family, love, joy and
                celebration, and out of this love for creating joyful memories
                and lasting impressions, we have an unwavering commitment to
                personalised and excellent customer service.
              </Text>
            </Box>
          </Flex>
        </Box>
      </Box>
    </Page>
  );
};
