import {
  Box,
  Flex,
  Heading,
  Spacer,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react';
import { Page } from '../../../components/Page';
import heroImage from '../../../assets/images/hero_banner.jpg';
import threetotwo from '../../../assets/images/placeholders/threetotwo.jpg';
import Menu from './Menu';


export const Menus = () => {
  return (
    <Page title="Amazing Catering in Harare">
      <Box>
        <Flex
          w={'full'}
          h={'0vh'}
          minH={{base:"200px",md:"400px"}}
          backgroundImage={`linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),url(${heroImage})`}
          backgroundSize={'cover'}
          backgroundRepeat="no-repeat"
          backgroundPosition={'center center'}
        >
          <VStack
            w={'full'}
            justify={'center'}
            px={useBreakpointValue({ base: 4, md: 8 })}
            bgGradient={'linear(to-r, blackAlpha.600, transparent)'}
          >
            <Stack
              maxW={'4xl'}
              align={'flex-start'}
              spacing={6}
              textAlign="center"
            >
              <Heading
                as="h1"
                color={'white'}
                fontWeight={200}
                lineHeight={1.2}
                textStyle="heading"
                fontFamily="Playfair Display"
                fontSize={useBreakpointValue({ base: '3xl', md: '6xl' })}
              >
                Wedding Catering
              </Heading>
              <Text
                width="full"
                textAlign="center"
                color={'white'}
                fontFamily="Playfair Display"
                lineHeight={1.2}
                fontSize={useBreakpointValue({ base: '2xl', md: '3xl' })}
              >
                Your Day, Our Focus
              </Text>
            </Stack>
          </VStack>
        </Flex>
        <Text
          width="full"
          textAlign="center"
          fontFamily="playfair display"
          lineHeight={1.2}
          my="8"
          mt="12"
          fontSize={useBreakpointValue({ base: '2xl', md: '5xl' })}
          fontWeight="100"
        >
          Browse through our Menus
        </Text>
        <Tabs
          mt="8"
          align="center"
          color="gray.400"
          mx={{ base: '2', md: '8' }}
          colorScheme="orange"
        >
          <TabList borderColor="gray.200" mb="10" fontFamily="playfair display">
            <Tab fontSize="lg">Deluxe Menu</Tab>
            <Tab fontSize="lg">Classic Menu</Tab>
            <Tab fontSize="lg">Italian Menu</Tab>
          </TabList>

          <TabPanels>
           <TabPanel style={{ background: '#3C3D3F' }} >
           <Menu name="Deluxe Menu" courses={[]}/>
            </TabPanel>
           <TabPanel style={{ background: '#3C3D3F' }} >
           <Menu name="Classic Menu" courses={[]}/>
            </TabPanel>
           <TabPanel style={{ background: '#3C3D3F' }} >
              <Menu name="Italian Menu" courses={[]}/>
            </TabPanel>
          </TabPanels>
        </Tabs>
        <Box
          mt="20"
          style={{
            background: `url(${threetotwo})`,
          }}
          backgroundSize="cover"
          bgRepeat="no-repeat"
        >
          <Flex opacity=".8">
            <Spacer></Spacer>
            <Box
              w={{ base: '100%', md: '50%' }}
              my="10"
              bg="white"
              color="gray.900"
              px="10"
              py="10"
            >
              <Heading
                fontFamily="Playfair Display"
                fontWeight="200"
                fontSize="5xl"
              >
                Lovingly Made <br /> Perfectly Delivered
              </Heading>
              <Text mt="6">
                Cater4u was born out of a passionate love for food - the
                ingredients; the aroma and flavours; the flair and creativity of
                presentation and the absolute joy and warmth that food brings to
                any gathering.
              </Text>
              <Text mt="6">
                At Cater4u, we are so passionate about our food that we hold
                ourselves to exceptionally high standards that focus on giving
                you individualised flawless service at every touch point - we
                CATER4U!
              </Text>
              <Text mt="6">
                For us, good food evokes warm feelings of family, love, joy and
                celebration, and out of this love for creating joyful memories
                and lasting impressions, we have an unwavering commitment to
                personalised and excellent customer service.
              </Text>
            </Box>
          </Flex>
        </Box>
      </Box>
    </Page>
  );
};
