import {
  Box,
  Flex,
  HStack,
  Icon,
  Image,
  Spacer,
  Text,
  Link,
  useDisclosure,
  IconButton,
  Collapse,
} from '@chakra-ui/react';
import { FaWhatsapp } from 'react-icons/fa';
import { FiX, FiMenu } from 'react-icons/fi';
import { Link as Rlink } from 'react-router-dom';
import logo from '../../assets/images/cater4u.webp';
import { RiShoppingCartFill } from 'react-icons/ri';
import { MainRoutes } from '../../routes/MainRoutes';
import { MainFooter } from '../../components/footers/MainFooter';
import NavLink from './Navlink';
import MobileNav from './MobileNav';
import { Link as RouterLink } from 'react-router-dom';
import Links from './DesktopLinks';
import { createContext, useEffect, useState } from 'react';
import { getBanners } from '../../services/banners.service';
import { IBanner } from '../../interfaces/BannerTypes';
import { RootStateOrAny, useSelector } from 'react-redux';

export const BannerContext = createContext<Array<IBanner>>([]);

export const MainLayout = () => {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const [banners, setBanners] = useState<Array<IBanner>>([]);
  const cart = useSelector((state: RootStateOrAny) => state);

  useEffect(() => {
    getBanners()
      .then(response => {
        setBanners(response?.data);
      })
      .catch();
  }, []);
  return (
    <BannerContext.Provider value={banners}>
      <Box bg="gray.50" color="gray.900">
        <Box>
          {/* pre nav bar */}
          <Flex px="8" bg="gray.900">
            <Spacer display={{ base: 'none', md: 'flex' }} />
            <Box my="2" fontSize="sm" color="white" mx="auto">
              <HStack>
                <Box>
                  <a
                    href="https://wa.me/263774161459?text=I'm%20inquiring%20about%20your%20services"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Icon as={FaWhatsapp} /> +263 774 161 459
                  </a>
                </Box>
                <Box>
                  <Text>
                    <a href="tel:+496170961709">+236 242 320 310</a>
                  </Text>
                </Box>
              </HStack>
            </Box>
          </Flex>
          {/* navbar */}
          <Box>
            <Flex
              bg="rgba(255,255,255,.9)"
              py="2"
              px={{ base: '6', md: '16' }}
              alignItems={'center'}
              justifyContent={'space-between'}
              minH={'60px'}
            >
              <Flex
                flex={{ base: 1, md: 'auto' }}
                ml={{ base: -2 }}
                display={{ base: 'flex', md: 'none' }}
              >
                <IconButton
                  onClick={onToggle}
                  icon={
                    isOpen ? (
                      <Icon w={3} h={3} as={FiX} />
                    ) : (
                      <Icon w={5} h={5} as={FiMenu} />
                    )
                  }
                  variant={'ghost'}
                  aria-label={'Toggle Navigation'}
                />
              </Flex>
              <Link
                to="/"
                as={RouterLink}
                _focus={{
                  boxShadow: 'none',
                }}
              >
                <Image src={logo} alt="cater 4 U logo" width="200px" />
              </Link>

              <Spacer />
              <Box textColor="gray.900" py="auto">
                <HStack
                  as={'nav'}
                  fontFamily="lexend"
                  spacing={4}
                  display={{ base: 'none', md: 'flex' }}
                >
                  {Links.map((link, i) => (
                    <NavLink url={link.url} key={i}>
                      {link.name}
                    </NavLink>
                  ))}
                  <Link
                    px={4}
                    py={2}
                    color="white"
                    // rounded={'md'}
                    position="relative"
                    transition="all .3s"
                    border="2px"
                    bg="red.600"
                    _hover={{
                      color: 'red.600',
                      bg: '#fff',
                    }}
                    to="/cart"
                    as={Rlink}
                  >
                    My Cart {'  '}{' '}
                    <Icon boxSize={6} as={RiShoppingCartFill}></Icon>
                    {cart.ShopProductsSlice.length +
                      cart.CaterToGoProductsSlice.length >
                      0 && (
                      <Box
                        position="absolute"
                        top="-4"
                        right="-4"
                        lineHeight= "30px"
                        borderRadius= "50%"
                        width="30px"
                        height="30px"
                        textAlign="center"
                        fontSize="15px"
                        border="solid 1px white"
                        color="white"
                        background="red.600"
                      >
                        {cart.ShopProductsSlice.length +
                          cart.CaterToGoProductsSlice.length}
                      </Box>
                    )}
                  </Link>
                </HStack>
              </Box>
            </Flex>
            <Collapse
              in={isOpen}
              animateOpacity
              style={{ position: 'absolute', width: '100vw', zIndex: 10000 }}
            >
              <MobileNav onClose={onClose} />
            </Collapse>
          </Box>
        </Box>
        {/* content is here */}
        <Box>
          <MainRoutes />
        </Box>
        {/* footer */}
        <MainFooter></MainFooter>
      </Box>
    </BannerContext.Provider>
  );
};
