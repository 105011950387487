import { IBanner } from '../interfaces/BannerTypes';

//create full path to image
function getImageURL(image: string) {
  return process.env.REACT_APP_RESOURCES_URL + '/' + image;
}

//get banner images by name
function getBannerImageByName(name: string, data: Array<IBanner>) {
  return getImageURL(
    data
      .filter(banner => {
        return banner.name === name;
      })
      .shift()?.image ?? ''
  );
}

export { getBannerImageByName, getImageURL };
