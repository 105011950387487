const Urls = [
    {
      name: 'Events',
      url: '/events',
    },
    {
      name: 'Hire',
      url: '/hire',
    },
    {
      name: 'Shop',
      url: '/shop',
    },
    {
      name: 'Cater to Go',
      url: '/cater-to-go',
    },
    {
      name: 'Contact',
      url: '/contact',
    },
  ];

  export default Urls