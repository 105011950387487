import {
    Box,
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    Textarea,
    NumberInput,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInputField,
    NumberInputStepper,
  } from '@chakra-ui/react';
  import { Field, Form, Formik } from 'formik';
  import { useEffect, useState } from 'react';
  import * as Yup from 'yup';
  import { toast } from 'react-toastify';
  import Card from '../../../../components/Card';
import { getDeliveryOption, updateDeliveryOption } from '../../../../services/deliveryOptions.service';
  
  const FormSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    position: Yup.number(),
  });
  
  interface IEditDeliveryOptionCard {
    deliveryoption_id: number | string;
  }
  
  const EditDeliveryOptionCard = ({ deliveryoption_id }: IEditDeliveryOptionCard) => {
    const [loading, setLoading] = useState(false);
    const [price, setPrice] = useState(1);
    const [ETA, setEta] = useState(1);
    const [deliveryoption, setDeliveryOption] = useState({
      name: '',
      description: '',
      price: '',
      ETA: '',
    });
  
    const handleChange = (valuesAsString: string, valueAsNumber: number) =>
      setPrice(valueAsNumber);
      
    const handleEtaChange = (valuesAsString: string, valueAsNumber: number) =>
      setEta(valueAsNumber);
  
    const [key, setKey] = useState(1);
  
    interface MyFormValues {
      name: string;
      description: string;
      price: string;
      ETA: string;
    }
    const initialValues: MyFormValues = {
      name: deliveryoption?.name ? deliveryoption?.name : '',
      description: deliveryoption?.description ? deliveryoption?.description : '',
      price: deliveryoption?.price ? deliveryoption?.price : '',
      ETA: deliveryoption?.price ? deliveryoption?.ETA : '',
    };
  
    function submit(data: any) {
      console.log(data);
      setLoading(true);
  
      updateDeliveryOption(deliveryoption_id, { ...data, price: price,ETA:ETA })
        .then((response: any) => {
          setKey(key ? 0 : 1);
          toast.info('DeliveryOption Updated');
          setDeliveryOption(response?.data?.data);
          setPrice(response?.data?.data?.price);
          console.log(response?.data?.data)
          return response?.data?.data?.id;
        })
        .catch((error: any) => {
          if (error?.response?.data?.data) {
            var errors = Object.values(error?.response?.data?.data);
            errors.forEach((value: any) => {
              toast.error(value[0]);
            });
          } else {
            toast.error('Error saving item');
          }
        });  
      setLoading(false);
    }
  
    useEffect(() => {
 
      getDeliveryOption(deliveryoption_id)
        .then((response: any) => {
          setDeliveryOption(response?.data?.data);
          setPrice(response?.data?.data?.price);
          setEta(response?.data?.data?.ETA);
        })
        .catch(() => toast.error('error loading deliveryoption'));
    }, [deliveryoption_id]);
  
    return (
      <Card
        width={{ base: '100%', md: '50%' }}
        mt="8"
        mb="4"
        px={{ base: '4', md: '4' }}
        borderRadius="md"
        shadow="md"
        py="4"
        key={key}
      >
        <Box>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={FormSchema}
            onSubmit={values => {
              submit(values);
            }}
          >
            {({ isSubmitting, values }) => (
              <Form>
                {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                <Field name="name">
                  {({ field, form }: { field: any; form: any }) => (
                    <FormControl
                      isInvalid={form.errors.name && form.touched.name}
                      isRequired
                      mt="8"
                    >
                      <FormLabel>Name</FormLabel>
                      <Input
                        {...field}
                        type="text"
                        placeholder="name"
                        _autofill={{
                          transition: 'all 5000s ease-in-out 0s',
                        }}
                      />
                      <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="description">
                  {({ field, form }: { field: any; form: any }) => (
                    <FormControl
                      isInvalid={
                        form.errors.description && form.touched.description
                      }
                      mt="8"
                    >
                      <FormLabel>Description</FormLabel>
                      <Textarea
                        placeholder="description"
                        {...field}
                        type="description"
                        _autofill={{
                          transition: 'all 5000s ease-in-out 0s',
                        }}
                      />
                      <FormErrorMessage>
                        {form.errors.description}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
  
                <Field name="price">
                  {({ field, form }: { field: any; form: any }) => (
                    <FormControl
                      isInvalid={form.errors.price && form.touched.price}
                      isRequired
                      mt="8"
                    >
                      <FormLabel>Price</FormLabel>
                      <NumberInput
                        defaultValue={deliveryoption?.price}
                        min={0}
                        value={price}
                        name="price"
                        id="price"
                        onChange={handleChange}
                      >
                        <NumberInputField />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                      <FormErrorMessage>{form.errors.price}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
  
                <Field name="ETA">
                {({ field, form }: { field: any; form: any }) => (
                  <FormControl
                    isInvalid={form.errors.ETA && form.touched.ETA}
                    mt="8"
                  >
                    <FormLabel>Eta (estimated minutes to deliver. pessimistic number recommended)</FormLabel>
                    <NumberInput
                      defaultValue={1}
                      min={1}
                      value={ETA}
                      name="ETA"
                      id="ETA"
                      onChange={handleEtaChange}
                    >
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                    <FormErrorMessage>{form.errors.ETA}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

                <Button
                  colorScheme="blue"
                  // w="full"
                  mt="6"
                  type="submit"
                  isLoading={loading}
                  loadingText="Submitting"
                >
                  Submit
                </Button>
              </Form>
            )}
          </Formik>
        </Box>
      </Card>
    );
  };
  
  export default EditDeliveryOptionCard;
  