import { Box} from '@chakra-ui/layout';
import * as React from 'react';


const FadeCard = ({
  image,
  children,
}: {
  image: string;
  children: React.ReactNode;
}) => {
  return (
    <Box
      backgroundColor="white"
      shadow="sm"
      _hover={{
        shadow: 'md',
      }}
    >
      <Box
        height={{base:"30vh",md:"50vh"}}
        minH="300px"
        w="100%"
        bg="red"
        backgroundImage={`linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1)),url(${image})`}
      ></Box>
      {children}
    </Box>
  );
};

export { FadeCard };
