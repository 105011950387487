import { Box, Image, Link, BoxProps } from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import swal from 'sweetalert';
import { deleteCourse } from '../../../../services/courses.service';
import { TableCard } from '../../../../components/TableCard';
import { getImageURL } from '../../../../common/ImageHelpers';
import { RouteNames } from '../../../../common/RouteNames';
import { getMenuCourses } from '../../../../services/menus.service';
import { image_placeholder } from '../../../../common/Placeholders';
const edit_link = RouteNames.admin.menus + '/';

interface IListCoursesCard extends BoxProps {
  menu_id: string | number;
}

export const ListCoursesCard = ({ menu_id, ...other }: IListCoursesCard) => {
  const [data, setData] = useState([]);
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Position',
        accessor: 'position',
      },
      {
        Header: 'Image',
        accessor: 'image',
        Cell: ({ row: { original } }: { row: any }) => {
          return (
            <Image
              src={
                original.image ? getImageURL(original.image) : image_placeholder
              }
              alt={original.name}
              fallbackSrc={image_placeholder}
              boxSize="60px"
              objectFit="contain"
            />
          );
        },
      },
      {
        disableSortBy: true,
        Header: 'Actions',
        accessor: 'actions',
        Cell: (tableProps: any) => (
          <>
            <Link
              as={RouterLink}
              to={edit_link+ tableProps.row.original.menu.id+'/courses/'+tableProps.row.original.id}
              color="blue.500"
            >
              Edit
            </Link>{' '}
            <Link
              color="red.500"
              onClick={() => {
                swal({
                  title: 'Are you sure?',
                  text: 'Once deleted, you will not be able to recover this item!',
                  icon: 'warning',
                  buttons: [true, true],
                  dangerMode: true,
                }).then(willDelete => {
                  if (willDelete) {
                    deleteCourse(tableProps.row.original.id)
                      .then(() => {
                        const dataCopy = [...data];
                        dataCopy.splice(tableProps.row.index, 1);
                        setData(dataCopy);
                        toast.info('Item removed');
                      })
                      .catch((error: Object | null) => {
                        toast.error('error removing Item');
                      });
                    swal('Item deleted!', {
                      icon: 'success',
                    });
                  } else {
                    // swal('Your imaginary file is safe!');
                  }
                });
              }}
            >
              Delete
            </Link>
          </>
        ),
      },
    ],
    [data]
  );

  useEffect(() => {
    getMenuCourses(menu_id)
      .then((response: any) => {
        console.log(response);
        setData(response?.data?.data);
      })
      .catch((error: any) => {
        console.log(error?.response);
      });
  }, [menu_id]);

  return (
    <Box>
      <TableCard
        data={data}
        columns={columns}
        edit_link={edit_link}
      ></TableCard>
    </Box>
  );
};
