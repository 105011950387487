import { axiosInstance } from '../common/API';
const BASE_PATH = 'menus';

interface DataType {
  [key: string]: string | boolean | number;
}

function getMenu(id: string | number) {
  return axiosInstance.get(`${BASE_PATH}/${id}`);
}

function getMenuCourses(id: string | number) {
  return axiosInstance.get(`${BASE_PATH}/${id}/courses`);
}

function getMenus() {
  return axiosInstance.get(`${BASE_PATH}`);
}

function storeMenu(data: DataType) {
  return axiosInstance.post(`${BASE_PATH}`, { ...data });
}

function updateMenu(id: string | number, data: DataType) {
  return axiosInstance.put(`${BASE_PATH}/${id}`, { ...data });
}

function deleteMenu(id: string | number) {
  return axiosInstance.delete(`${BASE_PATH}/${id}`);
}

function updateMenuEvents(id: string | number, data: DataType) {
  console.log(data)
  return axiosInstance.post(`${BASE_PATH}/${id}/events`,{...data});
}

function uploadMenuImage(id: string | number, data: FormData) {
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  return axiosInstance.post(`${BASE_PATH}/${id}/upload-image`, data, config);
}

export {
  getMenu,
  getMenus,
  storeMenu,
  updateMenu,
  updateMenuEvents,
  getMenuCourses,
  deleteMenu,
  uploadMenuImage,
};
