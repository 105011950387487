import {
    Box,
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Image,
    Input,
  } from '@chakra-ui/react';
  import { Field,  Form, Formik } from 'formik';
  import { useEffect, useState } from 'react';
  import * as Yup from 'yup';
  import { toast } from 'react-toastify';
  import { BoxProps } from '@chakra-ui/react';
import { getEvent, updateEvent, uploadEventImage } from '../../../../services/events.service';
import Card from '../../../../components/Card';
import { getImageURL } from '../../../../common/ImageHelpers';
import { image_placeholder } from '../../../../common/Placeholders';
import { Dropzone } from '../../../../components/Dropzone';
  const FormSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
  });
  
  interface IEvent {
    name: string;
    slug: string;
    subtitle: string
    image: string | null;
  }
  
  interface ComponentProps extends BoxProps{
    event_id: string,
  }
  
  const EditEventCard = ({ event_id,...other }: ComponentProps) => {
    const [loading, setLoading] = useState(false);
    const [event, setEvent] = useState<IEvent>({
      name: '',
      slug:'',
      subtitle:'',
      image: null,
    });
    const [images, setImages] = useState();
    const [key, setKey] = useState(1);
  
    function getImages(images: any) {
      setImages(images);
    }
  
    function formatFormdata(data: any) {
      const formData = new FormData();
      formData.append('image', data ? data[0] : null);
      return formData;
    }
  
    interface MyFormValues {
      name: string;
      subtitle: string
      slug: string
    }

    const initialValues: MyFormValues = {
      name: event.name,
      subtitle: event.subtitle,
      slug: event.slug
    };
  
    async function submit(data: any) {
      console.log(data);
      setLoading(true);
      var image = formatFormdata(images);
      // if (!images || typeof images[0] === 'undefined') {
      // if (!images) {
      //   toast.error('image required');
      //   setLoading(false);
      //   return;
      // }
  
      await updateEvent(event_id,data)
        .then((response: any) => {
          setKey(key ? 0 : 1);
          toast.info('Event Updated');
          setEvent(response?.data?.data);
        })
        .catch((error: any) => {
          if (error?.response?.data?.data) {
            var errors = Object.values(error?.response?.data?.data);
            errors.forEach((value: any) => {
              toast.error(value[0]);
            });
          } else {
            toast.error('Error saving item');
          }
        });
  
      if (event_id && images) {
        await uploadEventImage(event_id, image)
          .then((response: any) => {
            setEvent(response?.data?.data);
            toast.info('Image uploaded');
          })
          .catch((error: any) => {
            console.log(error);
            toast.error('error uploading image');
          });
      }
  
      setKey(key ? 0 : 1);
  
      setLoading(false);
    }
  
    //   setup form
    useEffect(() => {
      getEvent(event_id)
        .then((response: any) => {
          console.log(response?.data?.data)
          setEvent(response?.data?.data);
        })
        .catch((error: any) => {
          toast.error('error loading event');
        });
  
    }, [event_id]);
  
    return (
      <Card
        width="100%"
        mb="4"
        px={{ base: '4', md: '4' }}
        borderRadius="md"
        shadow="md"
        py="4"
        key={key}
        {...other}
      >
        <Box>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={FormSchema}
            onSubmit={values => {
              submit(values);
            }}
          >
            {({ isSubmitting, values }) => (
              <Form>
                {/* <pre>{JSON.stringify(initialValues, null, 2)}</pre> */}
                {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                {/* <Field name="name">
                  {({ field, form }: { field: any; form: any }) => (
                    <FormControl
                      isInvalid={form.errors.name && form.touched.name}
                      isRequired
                    >
                      <FormLabel>Name</FormLabel>
                      <Input
                        {...field}
                        type="text"
                        placeholder="name"
                        _autofill={{
                          transition: 'all 5000s ease-in-out 0s',
                        }}
                      />
                      <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field> */}

                {/* <Field name="slug" >
                  {({ field, form }: { field: any; form: any }) => (
                    <FormControl mt={8}
                      isInvalid={form.errors.slug && form.touched.slug}
                      isRequired
                    >
                      <FormLabel>Slug</FormLabel>
                      <Input
                        {...field}
                        type="text"
                        placeholder="slug"
                        _autofill={{
                          transition: 'all 5000s ease-in-out 0s',
                        }}
                      />
                      <FormErrorMessage>{form.errors.slug}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field> */}

                {/* <Field name="subtitle" >
                  {({ field, form }: { field: any; form: any }) => (
                    <FormControl mt={8}
                      isInvalid={form.errors.subtitle && form.touched.subtitle}
                      isRequired
                    >
                      <FormLabel>Subtitle</FormLabel>
                      <Input
                        {...field}
                        type="text"
                        placeholder="subtitle"
                        _autofill={{
                          transition: 'all 5000s ease-in-out 0s',
                        }}
                      />
                      <FormErrorMessage>{form.errors.subtitle}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field> */}
                <FormLabel mt="">Event Image</FormLabel>
                {event?.image && (
                  <Image
                    mt="8"
                    src={event.image ? getImageURL(event.image) : image_placeholder}
                    alt={event.name}
                    fallbackSrc={image_placeholder}
                    width={{ base: '100%', md: '40%' }}
                    objectFit="cover"
                  />
                )}
                <Dropzone mt="8" getImages={getImages}></Dropzone>
  
                <Button
                  colorScheme="blue"
                  // w="full"
                  mt="6"
                  type="submit"
                  isLoading={loading}
                  loadingText="Submitting"
                >
                  Submit
                </Button>
              </Form>
            )}
          </Formik>
        </Box>
      </Card>
    );
  };
  
  export default EditEventCard;
  