import {
  Box,
  Flex,
  Heading,
  Text,
  Button,
  Divider,
} from '@chakra-ui/react';

import { Page } from '../../../components/Page';
import { useContext, useEffect, useState } from 'react';
import { Header } from '../../../components/Header';
import { getEvents } from '../../../services/events.service';
import { Link } from 'react-router-dom';
import { FadeCard } from '../../../components/cards/FadeCard';
import { IEvent } from '../../../interfaces/EventInterfaces';
import { RouteNames } from '../../../common/RouteNames';
import { BannerContext } from '../../../layouts/MainLayout';
import { getImageURL } from '../../../common/ImageHelpers';
import { getBannerImageByName } from '../../../common/ImageHelpers';





export const Events = () => {
  const [events, setEvents] = useState<Array<IEvent>>([]);
  const banners = useContext(BannerContext)

  useEffect(() => {
    getEvents()
      .then((response: any) => {
        setEvents(response?.data?.data);
      })
      .catch(error => {});
  }, []);

  return (
    <Page title="Amazing Catering in Harare">
      <Box>
        <Header
          title="Events"
          subtitle="Find the perfect Menus for your Event"
          backgroundImage={getBannerImageByName('eventspagebanner',banners)}
        />
        {/* <pre>{JSON.stringify(cart, null, 1)}</pre> */}
        <pre>{/* {JSON.stringify(products,null,1)} */}</pre>
        <Flex my="20" width={{ base: '100%', md: '90%' }} mx="auto" wrap="wrap">
          {events.map((event, i) => {
            return (
              <Box w={{ base: '100%', md: '33%' }} p="2" key={i}>
                <Box p="4" backgroundColor="gray.200">
                <FadeCard image={getImageURL(event.image)}>
                  <Box px="4">
                    <Heading
                      mt="-10"
                      fontFamily="playfair"
                      fontSize="3xl"
                      px="4"
                      fontWeight="500"
                      color="gray.900"
                      textAlign="center"
                    >
                      {event.name}
                    </Heading>
                    <Box>
                      <Divider
                        variant="solid"
                        borderColor="gray.500"
                        my="4"
                        w="100px"
                        mx="auto"
                      />
                    </Box>
                    <Box
                      color=""
                      textAlign="center"
                      fontFamily="playfair"
                      my="10"
                    >
                      {event.menus.map((menu, i) => {
                        return <Text my="4" key={i}>{menu.name}</Text>;
                      })}
                    </Box>
                    <Link to={RouteNames.events + '/' + event.slug}>
                      <Button
                        w="full"
                        borderRadius="0"
                        colorScheme="red"
                        mb="5"
                        size="lg"
                      >
                        View Menus In Detail
                      </Button>
                    </Link>
                  </Box>
                </FadeCard>
                </Box>
           </Box>
            );
          })}
        </Flex>
      </Box>
    </Page>
  );
};
