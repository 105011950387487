import {
  Box,
  // Button,
  // chakra,
  // Circle,
  Flex,
  Heading,
  // HStack,
  // Icon,
  // Image,
  // Link,
  Spacer,
  Text,
  // Tooltip,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import threetotwo from '../../../assets/images/placeholders/threetotwo.jpg';
import { Page } from '../../../components/Page';
// import HIreItemCard from './HireItemCard';
import { useContext, useEffect, useState } from 'react';
import { getHireItems } from '../../../services/hireitems.service';
import HireItemCard from './HireItemCard';
import { Header } from '../../../components/Header';
import { IHireItem } from '../../../interfaces/HireItemsInterface';
import { BannerContext } from '../../../layouts/MainLayout';
import { getBannerImageByName } from '../../../common/ImageHelpers';
// import {useSelector} from "react-redux"

export const Hire = () => {
  const [hireitems, setHireItems] = useState<IHireItem[]>([]);
  // const products = useSelector((state: IHireItem[]) => state)
  const banners = useContext(BannerContext)

  useEffect(() => {
    getHireItems()
      .then((response: any) => {
        setHireItems(response?.data?.data);
      })
      .catch(error => {});
  }, []);

  return (
    <Page title="Amazing Catering in Harare">
      <Box>
        <Header
          title="Hire Items"
          subtitle="We have all the crockery & cutlery and glassware essentials
              required for your special function"
              backgroundImage={getBannerImageByName('hirepagebanner',banners)}
        />
        <pre>
          {/* {JSON.stringify(products,null,1)} */}
        </pre>
        <Box w="" mx={{ base: '1', md: '1' }} mt="10">
          <Wrap w="" justify="center">
            {hireitems.map((hireitem: IHireItem, i) => {
              return (
                <WrapItem key={i} w={{ base: '100%', md: '30%' }} px="2">
                  <HireItemCard
                    id={hireitem.id}
                    price={hireitem.price}
                    minimum={hireitem.minimum}
                    quantity={0}
                    name={hireitem.name}
                    description={hireitem.description}
                    image={hireitem.image}
                  ></HireItemCard>
                </WrapItem>
              );
            })}
          </Wrap>
        </Box>
        {/* 
        <Wrap mx="1" mt="8">
          <WrapItem w="15%" style={{ margin: 0 }}>
            <Box w="full" px="4" position="sticky" top="20px">
              <Heading
                fontSize="2xl"
                fontWeight="500"
                mb="4"
                fontFamily="Playfair regular"
              >
                Categories
              </Heading>
              <Box w="100%">
                <Link>All</Link>
              </Box>
              <Box w="100%">
                <Link>Cuttlery</Link>
              </Box>
              <Box w="100%">
                <Link>Chairs</Link>
              </Box>
            </Box>
          </WrapItem>
          <WrapItem w="60%" style={{ margin: 0 }}>
            <Wrap>
              {items.map(() => {
                return (
                  <WrapItem
                    w={{ base: '100%', md: '33%' }}
                    m="0"
                    style={{ margin: '0px' }}
                    px="2"
                    py="2"
                  >
                    <ProductAddToCart />
                  </WrapItem>
                );
              })}
            </Wrap>
          </WrapItem>
          <WrapItem w="25%" style={{ margin: 0 }}>
            <Box
              w="full"
              position="sticky"
              top="20px"
              shadow="md"
              p="4"
              borderRadius="md"
              textAlign="center"
              mx="2"
            >
              <Heading
                fontSize="2xl"
                as="h4"
                fontWeight="400"
                fontFamily="Playfair regular"
                borderBottom="1px"
                borderColor="gray.400"
                pb="4"
              >
                Your Order
              </Heading>
               {cartItems.map((item,index)=>{
                  return <CartItem name={item.name} scale={item.scale}></CartItem>
              })} 

              <Box borderTop="1px" borderColor="gray.400" w="100%" my="4"></Box>
              <HStack>
                <Text textAlign="left">Total </Text>
                <Spacer></Spacer>
                <Text textAlign="left" fontWeight="900">
                  $600{' '}
                </Text>
              </HStack>
              <Box borderTop="1px" borderColor="gray.400" w="100%" my="4"></Box>
              <Button colorScheme="orange" color="white" w="full" mt="">
                Place Order
              </Button>
            </Box>
          </WrapItem>
        </Wrap>
         */}
        <Box
          mt="20"
          style={{
            background: `url(${threetotwo})`,
          }}
          backgroundSize="cover"
          bgRepeat="no-repeat"
        >
          <Flex opacity=".8">
            <Spacer></Spacer>
            <Box
              w={{ base: '100%', md: '50%' }}
              my="10"
              bg="white"
              color="gray.900"
              px="10"
              py="10"
            >
              <Heading
                fontFamily="Playfair Display"
                fontWeight="200"
                fontSize="5xl"
              >
                Lovingly Made <br /> Perfectly Delivered
              </Heading>
              <Text mt="6">
                Cater4u was born out of a passionate love for food - the
                ingredients; the aroma and flavours; the flair and creativity of
                presentation and the absolute joy and warmth that food brings to
                any gathering.
              </Text>
              <Text mt="6">
                At Cater4u, we are so passionate about our food that we hold
                ourselves to exceptionally high standards that focus on giving
                you individualised flawless service at every touch point - we
                CATER4U!
              </Text>
              <Text mt="6">
                For us, good food evokes warm feelings of family, love, joy and
                celebration, and out of this love for creating joyful memories
                and lasting impressions, we have an unwavering commitment to
                personalised and excellent customer service.
              </Text>
            </Box>
          </Flex>
        </Box>
      </Box>
    </Page>
  );
};

// const cartItems = [
//     {name:"plates",scale:5},
//     {name:"glass set A",scale:10},
//     {name:"Tent",scale:1},
// ]

// const data = {
//   isNew: true,
//   imageURL: cat2,
//   name: 'Wayfarer Classic',
//   price: 4.5,
//   rating: 4.2,
//   numReviews: 34,
// };

// function ProductAddToCart() {
//   return (
//     <Flex w="full" alignItems="center" justifyContent="center">
//       <Box
//         // bg={useColorModeValue('white', 'gray.800')}
//         bg="white"
//         w="100%"
//         borderWidth="1px"
//         rounded="lg"
//         shadow="md"
//         color="white"
//         position="relative"
//       >
//         {data.isNew && (
//           <Circle
//             size="10px"
//             position="absolute"
//             top={2}
//             right={2}
//             bg="red.200"
//           />
//         )}

//         <Image
//           src={data.imageURL}
//           alt={`Picture of ${data.name}`}
//           roundedTop="lg"
//         />

//         <Box p="4">
//           <Box d="flex" alignItems="baseline"></Box>
//           <Flex mt="1" justifyContent="space-between" alignContent="center">
//             <Box
//               fontSize="xl"
//               fontWeight="semibold"
//               color="gray.900"
//               as="h4"
//               lineHeight="tight"
//               isTruncated
//               w="full"
//             >
//               <Text textAlign="center" w="full">
//                 {data.name}
//               </Text>
//             </Box>
//             <Tooltip
//               label="Add to cart"
//               bg="white"
//               placement={'top'}
//               color={'gray.800'}
//               fontSize={'1.2em'}
//             >
//               <chakra.a href={'#'} display={'flex'}>
//                 {/* <Icon as={FiShoppingCart} h={7} w={7} alignSelf={'center'} /> */}
//               </chakra.a>
//             </Tooltip>
//           </Flex>
//           <Flex justifyContent="space-between" alignContent="center">
//             <Button colorScheme="green" w="full" mt="6">
//               <Icon as={FiPlus} mr="2"></Icon>
//               Add to Order
//             </Button>
//           </Flex>
//         </Box>
//       </Box>
//     </Flex>
//   );
// }
