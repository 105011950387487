import { Route, Switch } from 'react-router-dom';
import { Cart } from '../pages/front/Cart';
import { CaterToGo } from '../pages/front/CaterToGo';
import { ContactUs } from '../pages/front/ContactUs';
import { Events } from '../pages/front/Events';
import { Hire } from '../pages/front/Hire';
import { Home } from '../pages/front/Home';
import { Menus } from '../pages/front/Menus';
import { EventMenus } from '../pages/front/Menus/EventMenus';
// import { CaterToGo } from '../pages/front/Shop/CaterToGoPage';
import { Shop } from '../pages/front/Shop';

export const MainRoutes = () => {
  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/menus" exact component={Menus} />
      <Route path="/events" exact component={Events} />
      <Route path="/events/:menu_slug" exact component={EventMenus} />
      <Route path="/shop" exact component={Shop} />
      <Route path="/cater-to-go" exact component={CaterToGo} />
      <Route path="/hire" exact component={Hire} />
      <Route path="/cart" exact component={Cart}/>
      <Route path="/contact" exact component={ContactUs}/>
    </Switch>
  );
};
