import {
  Box,
  Button,
  Text,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Image,
  Textarea,
  NumberInput,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInputField,
  NumberInputStepper,
} from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import {
  getCourse,
  updateCourse,
  uploadCourseImage,
} from '../../../../services/courses.service';
import Card from '../../../../components/Card';
import { Dropzone } from '../../../../components/Dropzone';
import { image_placeholder } from '../../../../common/Placeholders';
import { getImageURL } from '../../../../common/ImageHelpers';

const FormSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  position: Yup.number(),
});
interface IEditCourseCard {
  course_id: number | string;
}

interface ICourse {
  position: number;
  price: string;
  description: string;
  name: string;
  image: string;
  menu_id:number
}

const EditCourseCard = ({ course_id }: IEditCourseCard) => {
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState();
  const [course, setCourse] = useState<ICourse>({
    image: '',
    name: '',
    menu_id:0,
    position: 90,
    price: '',
    description: '',
  });
  const [value, setValue] = useState(course?.position);
  const handleChange = (valuesAsString: string, valueAsNumber: number) =>
    setValue(valueAsNumber);
  const [key, setKey] = useState(1);

  function getImages(images: any) {
    setImages(images);
  }

  function formatFormdata(data: any) {
    const formData = new FormData();
    formData.append('image', data ? data[0] : null);
    return formData;
  }

  interface MyFormValues {
    name: string;
    description: string;
    price: string;
    position: number | null;
    menu_id:number
  }
  const initialValues: MyFormValues = {
    name: course?.name,
    description: course?.description,
    price: course?.price,
    position: value,
    menu_id: course?.menu_id
  };

  async function submit(data: any) {
    console.log(data);
    setLoading(true);
    var image = formatFormdata(images);
    // if (!images || typeof images[0] === 'undefined') {
    // if (!images) {
    //   toast.error('image required');
    //   setLoading(false);
    //   return;
    // }

    var id = await updateCourse(course_id,data)
      .then((response: any) => {
        setKey(key ? 0 : 1);
        toast.info('Course Updated');
        setCourse(response?.data?.data);
        setValue(response?.data?.data?.position)
        return response?.data?.data?.id;
      })
      .catch((error: any) => {
        if (error?.response?.data?.data) {
          var errors = Object.values(error?.response?.data?.data);
          errors.forEach((value: any) => {
            toast.error(value[0]);
          });
        } else {
          toast.error('Error saving item');
        }
      });

    if (id && images) {
      await uploadCourseImage(id, image)
        .then((response: any) => {
          setCourse(response?.data?.data);
          setValue(response?.data?.data?.position)
          toast.info('Image uploaded');
        })
        .catch((error: any) => {
          console.log(error);
          toast.error('error uploading image');
        });
    }

    setKey(key ? 0 : 1);

    setLoading(false);
  }

  useEffect(() => {
    getCourse(course_id)
      .then(response => {
        console.log(response?.data?.data)
        setCourse(response?.data?.data);
        setValue(response?.data?.data?.position)
      })
      .catch(error => {
        toast.error('Error fetching course');
      });
  }, [course_id]);

  return (
    <Card
      width={{ base: '100%', md: '50%' }}
      mt="8"
      mb="4"
      px={{ base: '4', md: '4' }}
      borderRadius="md"
      shadow="md"
      py="4"
      key={key}
    >
      <Box>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={FormSchema}
          onSubmit={values => {
            submit({...values, position: value });
          }}
        >
          {({ isSubmitting, values }) => (
            <Form>
              {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
              <Field name="name">
                {({ field, form }: { field: any; form: any }) => (
                  <FormControl
                    isInvalid={form.errors.name && form.touched.name}
                    isRequired
                    mt="8"
                  >
                    <FormLabel>Title</FormLabel>
                    <Input
                      {...field}
                      type="text"
                      placeholder="name"
                      _autofill={{
                        transition: 'all 5000s ease-in-out 0s',
                      }}
                    />
                    <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name="description">
                {({ field, form }: { field: any; form: any }) => (
                  <FormControl
                    isRequired
                    isInvalid={
                      form.errors.description && form.touched.description
                    }
                    mt="8"
                  >
                    <FormLabel>Description</FormLabel>
                    <Textarea
                      placeholder="description"
                      {...field}
                      type="description"
                      _autofill={{
                        transition: 'all 5000s ease-in-out 0s',
                      }}
                    />
                    <FormErrorMessage>
                      {form.errors.description}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name="position">
                {({ field, form }: { field: any; form: any }) => (
                  <FormControl
                    isInvalid={form.errors.position && form.touched.position}
                    isRequired
                    mt="8"
                  >
                    <FormLabel>Position</FormLabel>
                    <NumberInput
                      defaultValue={0}
                      min={0}
                      value={value}
                      name="position"
                      id="position"
                      onChange={handleChange}
                    >
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                    {/* <Input
                        {...field}
                        type="number"
                        placeholder="number"
                        _autofill={{
                          transition: 'all 5000s ease-in-out 0s',
                        }}
                      /> */}
                    <FormErrorMessage>{form.errors.position}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              {/* <Field name="price">
                {({ field, form }: { field: any; form: any }) => (
                  <FormControl
                    isInvalid={form.errors.price && form.touched.price}
                    mt="8"
                  >
                    <FormLabel>Price</FormLabel>
                    <Input
                      {...field}
                      type="text"
                      placeholder="Price per Platter"
                      _autofill={{
                        transition: 'all 5000s ease-in-out 0s',
                      }}
                    />
                    <FormErrorMessage>{form.errors.price}</FormErrorMessage>
                  </FormControl>
                )}
              </Field> */}

              {/* <Text mt="8">Image</Text>
              {course?.image && (
                <Image
                  mt="8"
                  src={
                    course.image ? getImageURL(course.image) : image_placeholder
                  }
                  alt={course.name}
                  fallbackSrc={image_placeholder}
                  width={{ base: '100%', md: '40%' }}
                  objectFit="cover"
                />
              )}
              <Dropzone getImages={getImages} mt="2"></Dropzone> */}

              <Button
                colorScheme="blue"
                // w="full"
                mt="6"
                type="submit"
                isLoading={loading}
                loadingText="Submitting"
              >
                Submit
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
    </Card>
  );
};

export default EditCourseCard;
