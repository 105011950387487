import { axiosInstance } from '../common/API';
const BASE_PATH = 'courses';

interface DataType {
  [key: string]: string | boolean | number;
}

function getCourse(id: string | number) {
  return axiosInstance.get(`${BASE_PATH}/${id}`);
}

function getCourses() {
  return axiosInstance.get(`${BASE_PATH}`);
}

function storeCourse(data: DataType) {
  return axiosInstance.post(`${BASE_PATH}`, { ...data });
}

function updateCourse(id: string | number, data: DataType) {
  return axiosInstance.put(`${BASE_PATH}/${id}`, { ...data });
}

function deleteCourse(id: string | number) {
  return axiosInstance.delete(`${BASE_PATH}/${id}`);
}

function updateCourseEvents(id: string | number, data: DataType) {
  console.log(data)
  return axiosInstance.post(`${BASE_PATH}/${id}/events`,{...data});
}

function uploadCourseImage(id: string | number, data: FormData) {
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  return axiosInstance.post(`${BASE_PATH}/${id}/upload-image`, data, config);
}

export {
  getCourse,
  getCourses,
  storeCourse,
  updateCourse,
  updateCourseEvents,
  deleteCourse,
  uploadCourseImage,
};
