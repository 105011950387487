import * as React from 'react';
import loader from '../assets/images/loader.svg';
const Loader = () => {
  return (
    <div
      style={{
        position: 'fixed',
        width: '100vw',
        height: '100vh',
        background: 'white',
        zIndex: 10000,
      }}
    >
      <img
        src={loader}
        alt="loader"
        style={{
          position: 'fixed',
          width: '40px',
          height: '40px',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      />
    </div>
  );
};

export default Loader;
