import {
  Box,
  Flex,
  Heading,
  Image,
  Spacer,
  Stack,
  Text,
  useBreakpointValue,
  VStack,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import threetotwo from '../../../assets/images/placeholders/threetotwo.jpg';
import Card from './Card';
import { Page } from '../../../components/Page';
import { useContext, useEffect, useMemo, useState } from 'react';
import { getEvents } from '../../../services/events.service';
import { RouteNames } from '../../../common/RouteNames';
import { IBanner } from '../../../interfaces/BannerTypes';
import { BannerContext } from '../../../layouts/MainLayout';
import { threeXtwo } from '../../../common/Assets';
import { getImageURL } from '../../../common/ImageHelpers';

interface Event {
  name: string;
  image: string;
  slug: string;
  id: number;
}

export const Home = () => {
  const [events, setEvents] = useState([]);
  // const [banners, setBanners] = useState<Array<IBanner>>([]);
  const banners = useContext(BannerContext);

  const menus = useMemo(
    () => [
      {
        name: 'Cater to go  ',
        slug: 'catertogo',
        image: getBannerImageByName('catertogocardimage', banners),
        cta: 'View More',
      },
      {
        name: 'Our Shop',
        slug: 'shop',
        image: getBannerImageByName('shopcardimage', banners),
        cta: 'View More',
      },
      {
        name: 'Event Hire',
        slug: 'hire',
        image: getBannerImageByName('hirecardimage', banners),
        cta: 'View More',
      },
    ],
    [banners]
  );

  function getBannerImageByName(name: string, data: Array<IBanner>) {
    return (getImageURL(
      data
        .filter(banner => {
          return banner.name === name;
        })
        .shift()?.image ?? '')
    );
  }

  useEffect(() => {
    getEvents()
      .then((response: any) => {
        setEvents(response?.data?.data);
      })
      .catch();

    // getBanners()
    //   .then(response => {
    //     setBanners(response?.data);
    //   })
    //   .catch();
  }, []);

  return (
    <Page title="Amazing Catering in Harare">
      <Box>
        <Flex
          w={'full'}
          h={{ base: '80vh', md: '100vh' }}
          minH={{ base: '90px', md: '400px' }}
          backgroundImage={`linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),url(${getBannerImageByName('homepagebanner',banners)})`}
          backgroundSize={'cover'}
          backgroundRepeat="no-repeat"
          backgroundAttachment="fixed"
          backgroundPosition={'center center'}
        >
          <VStack
            w={'full'}
            justify={'center'}
            px={useBreakpointValue({ base: 4, md: 8 })}
            bgGradient={'linear(to-r, blackAlpha.600, transparent)'}
          >
            <Stack
              maxW={'4xl'}
              mt="10"
              align={'flex-start'}
              spacing={6}
              textAlign="center"
            >
              <Heading
                as="h1"
                color={'white'}
                fontWeight={200}
                lineHeight={1.2}
                textStyle="heading"
                fontFamily="Playfair Display"
                fontSize={useBreakpointValue({ base: '3xl', md: '6xl' })}
              >
                AMAZING CATERING FOR EVERY OCCASION
              </Heading>
              <Text
                width="full"
                textAlign="center"
                color={'white'}
                fontFamily="Playfair Display"
                lineHeight={1.2}
                fontSize={useBreakpointValue({ base: '2xl', md: '3xl' })}
              >
                Weddings | Corporate Events | Conferences | Social Events
              </Text>
            </Stack>
          </VStack>
        </Flex>
        <Box w="" mt="-5em" mx={{ base: '1', md: '1' }}>
          <Wrap w="" justify="center">
            {events.map((event: Event, i) => {
              return (
                <WrapItem key={i} w={{ base: '100%', md: '30%' }} px="2">
                  <Card
                    name={event.name}
                    image={getImageURL(event.image)}
                    url={RouteNames.events + '/' + event.slug}
                    cta={'View Menus'}
                  ></Card>
                </WrapItem>
              );
            })}
            {menus.map((item, index) => {
              return (
                <WrapItem key={index} w={{ base: '100%', md: '30%' }} px="2">
                  <Card
                    name={item.name}
                    image={item.image}
                    url={item.slug}
                    cta={item.cta}
                  ></Card>
                </WrapItem>
              );
            })}
          </Wrap>
        </Box>

        <Box
          mt="20"
          style={{
            background: `url(${threetotwo})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
        >
          <Flex opacity="1">
            <Spacer></Spacer>
            <Box
              w={{ base: '100%', md: '40%' }}
              my="10"
              bg="white"
              color="gray.900"
              px="10"
              py="10"
              mx={{ base: '4', md: '0' }}
              mr={{ md: '10' }}
            >
              <Heading
                fontFamily="Playfair Display"
                fontWeight="200"
                fontSize="5xl"
              >
                Lovingly Made <br /> Perfectly Delivered
              </Heading>
              <Box fontSize="lg">
                <Text mt="6">
                  Cater4u was born out of a passionate love for food - the
                  ingredients; the aroma and flavours; the flair and creativity
                  of presentation and the absolute joy and warmth that food
                  brings to any gathering.
                </Text>
                <Text mt="6">
                  At Cater4u, we are so passionate about our food that we hold
                  ourselves to exceptionally high standards that focus on giving
                  you individualised flawless service at every touch point - we
                  CATER4U!
                </Text>
                <Text mt="6">
                  For us, good food evokes warm feelings of family, love, joy
                  and celebration, and out of this love for creating joyful
                  memories and lasting impressions, we have an unwavering
                  commitment to personalised and excellent customer service.
                </Text>
              </Box>
            </Box>
          </Flex>
        </Box>

        <Heading
          as="h2"
          width="full"
          textAlign="center"
          fontFamily="playfair"
          lineHeight={1.2}
          fontWeight="100"
          my="20"
          fontSize={useBreakpointValue({ base: '2xl', md: '5xl' })}
        >
          It's all about the food
        </Heading>

        <Box w="">
          <Wrap w="100%" mx="auto" style={{ margin: '0px' }} overflow="hidden">
            {banners.filter((banner)=>{
              return banner.name === 'homepagegallery'
            }).map((item, index) => {
              return (
                <WrapItem
                  key={index}
                  px={{ base: 2, md: 0 }}
                  py={{ base: 2, md: 0 }}
                  w={{ base: '50%', md: '25%' }}
                  style={{ margin: 0 }}
                >
                  <Image src={getImageURL(item.image)} fallbackSrc={threeXtwo}></Image>
                </WrapItem>
              );
            })}
          </Wrap>
        </Box>
      </Box>
    </Page>
  );
};
