import { Box, BoxProps } from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 6,
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: '30%',
  // height: 100,
  padding: 4,
  boxSizing: 'border-box',
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%',
};

const baseStyle = {
  flex: 1,
  display: 'flex',
  // flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: 'rgba(115, 187, 239, 0.66)',
  borderStyle: 'dashed',
  // backgroundColor: '#2D3748',
  backgroundColor: 'white',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const activeStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

interface FilesType {}

interface DropZone extends BoxProps{
  getImages: (...args: any[]) => any;
  maxFiles?: number;
}

const Dropzone = ({
  getImages,
  maxFiles = 1,
  ...rest
}: DropZone) => {
  const [files, setFiles] = useState<FilesType[]>([]);
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    maxFiles: maxFiles,
    accept: 'image/*',
    onDrop: acceptedFiles => {
      getImages(acceptedFiles);
      setFiles(
        acceptedFiles.map((value: object) =>
          Object.assign(value, { preview: URL.createObjectURL(value) })
        )
      );
    },
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const thumbs = files.map((file: any) => (
    <Box sx={thumb} key={file.name}>
      <div style={thumbInner}>
        <img src={file.preview} style={img} alt="" />
      </div>
    </Box>
  ));

  useEffect(
    () => () => {
      files.forEach((file: any) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  return (
    <Box className="container" {...rest}>
      <div {...getRootProps({style})} >
        <input {...getInputProps()} />
        <p>Drag 'n' drop some images here, or click to select images</p>
      </div>
      {thumbs.length>0 && <Box sx={thumbsContainer}>{thumbs}</Box>} 
    </Box>
  );
};

export { Dropzone };
