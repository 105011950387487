import { Stack, Text, Flex, Icon } from '@chakra-ui/react';
import * as React from 'react';
import { RiShoppingCartFill } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import Links from './Links';
import MobileNavItem from './MobileNavItem';

const MobileNav = ({ onClose }: { onClose: () => void }) => {
  return (
    <Stack bg="white" p={4} display={{ md: 'none' }}>
      {Links.map((navItem, index) => (
        <MobileNavItem key={index} label={navItem.name} link={navItem.url} onClose={onClose}/>
      ))}
      <Stack spacing={4}>
        <Link to="/cart">
          <Flex
            onClick={onClose}
            py={2}
            justify={'space-between'}
            align={'center'}
            _hover={{
              textDecoration: 'none',
            }}
          >
            <Text fontWeight={600} color="red.600">
              <Icon boxSize={4} as={RiShoppingCartFill}></Icon> Cart
            </Text>
          </Flex>
        </Link>
      </Stack>
    </Stack>
  );
};

export default MobileNav;
