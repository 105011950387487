import { Box, Flex, Select } from '@chakra-ui/react';
import { useContext, useEffect, useState } from 'react';
import { getBannerImageByName } from '../../common/ImageHelpers';
import { ICategory } from '../../interfaces/CategoryTypes';
import { IProduct } from '../../interfaces/ProductInterfaces';
import { BannerContext } from '../../layouts/MainLayout';
import { getCategories } from '../../services/categories.service';
import { getProducts } from '../../services/products.service';
import { Header } from '../Header';
import ProductCard from './ProductCard';

export const ShopCard = ({
  filterName = 'shop',
  title = 'Shop',
  subtitle = 'Spice up your Cooking with our authentic flavours',
}) => {
  // const [products, setProducts] = useState<IProduct[]>([]);
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [products, setProducts] = useState<IProduct[]>([]);
  const [filtered, setFiltered] = useState<IProduct[]>([]);
  const banners = useContext(BannerContext)

    const filter = (category_id: number) => {
      console.log('filtering',!isNaN(category_id))
      if (!isNaN(category_id)) {
        const results = products.filter(product => {
          return product.category_id === category_id;
          // Use the toLowerCase() method to make it case-insensitive
        });
        setFiltered(results);
      } else {
        setFiltered(products);
        // If the text field is empty, show all users
      }
    };

  useEffect(() => {
    getProducts(filterName)
      .then(repsonse => {
        setProducts(repsonse?.data?.data);
        setFiltered(repsonse?.data?.data);
      })
      .catch();

    getCategories(filterName).then(response => {
      setCategories(response?.data?.data);
    });

    // console.log(cart);
  }, [filterName]);

  return (

      <Box>
        <Header title={title} subtitle={subtitle} backgroundImage={getBannerImageByName(filterName==="shop"?'shoppagebanner':'catertogopagebanner',banners)}/>
        <Box w="" mx={{ base: '1', md: '1' }} my="10">
          <Flex w="100%" justify="center" px={{base:"0",md:"10"}} flexWrap="wrap">
            <Box w={{base:"100%",md:"20%"}} my="2" px="2">
              {/* <CategoriesSideBar categories={categories}/> */}
              <Box backgroundColor="gray.200" p="4">
                <Select
                  placeholder="All Categories"
                  isRequired
                  borderRadius="0"
                  backgroundColor="white"
                  onChange={(e)=>filter(parseInt(e.target.value))}
                >
                  {categories.map((category,i)=>{
                    return <option value={category.id} key={i}>{category.name}</option>
                  })}
                </Select>
              </Box>
            </Box>
            <Flex w={{base:"100%",md:"80%"}} wrap="wrap" my="2">
              {filtered.map((product: IProduct, i) => {
                return (
                  <Box key={i} w={{ base: '100%', md: '33%' }} px="2" mb="4">
                    <ProductCard
                      id={product.id}
                      category_id={product.category_id}
                      quantity={0}
                      price={product.price}
                      name={product.name}
                      description={product.description}
                      image={product.image}
                    ></ProductCard>
                  </Box>
                );
              })}
            </Flex>
          </Flex>
        </Box>
      </Box>

  );
};