import {
  Box,
  // Button,
  // chakra,
  // Circle,
  Flex,
  Heading,
  // HStack,
  // Icon,
  // Image,
  // Link,
  Select,
  Spacer,
  Text,
  List,
  ListItem,
  ListIcon,
  // Tooltip,
  FormControl,
  Input,
  FormLabel,
  FormErrorMessage,
  Button,
  Divider,
} from '@chakra-ui/react';
import threetotwo from '../../../assets/images/placeholders/threetotwo.jpg';
import { Page } from '../../../components/Page';
// import HIreItemCard from './HireItemCard';
import { useContext, useEffect, useRef, useState } from 'react';
// import { getHireItems } from '../../../services/hireitems.service';
//   import HireItemCard from './HireItemCard';
import { Header } from '../../../components/Header';
// import { IHireItem } from '../../../interfaces/HireItemsInterface';
import { RootStateOrAny, useSelector } from 'react-redux';
import { CartItem } from './CartItem';
import { IProduct } from '../../../interfaces/ProductInterfaces';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import store from '../../../common/store';
import { clearCart } from '../../../slices/CaterToGoProductsSlice';
import { clearCart as clearShopCart } from '../../../slices/ShopProductsSlice';
import { FadeCard } from '../../../components/cards/FadeCard';
import { Link } from 'react-router-dom';
import { storeOrder } from '../../../services/orders.service';
import { toast } from 'react-toastify';
import { BannerContext } from '../../../layouts/MainLayout';
import { getBannerImageByName } from '../../../common/ImageHelpers';
import { pad } from '../../../common/Helpers';
import { FiCreditCard } from 'react-icons/fi';
import { RiMoneyDollarCircleFill } from 'react-icons/ri';
import { getDeliveryOptions } from '../../../services/deliveryOptions.service';
import { IDeliveryOption } from '../../../interfaces/DeliveryOptionTypes';
import { IOrder } from '../../../interfaces/OrderTypes';

// TODO finish adding delivery options

interface MyFormValues {
  name: string;
  phone: string;
  whatsapp: string;
}
const initialValues: MyFormValues = {
  name: '',
  phone: '',
  whatsapp: '',
};

const FormSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  // email: Yup.string().email().required('Required').min(1),
  phone: Yup.string().required('Required').min(1),
});

export const Cart = () => {
  const banners = useContext(BannerContext);
  const cart = useSelector((state: RootStateOrAny) => state);
  const [subtotal, setSubTotal] = useState(0);
  const [loading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [deliveryOptions, setDeliveryOptions] =
    useState<Array<IDeliveryOption>>();
  const [order, setOrder] = useState<IOrder>({ id: ' ', address: '', deliveryoption: null,total:'' });
  const [deliveryOption, setDeliveryOption] = useState<IDeliveryOption>();

  function submit(values: any) {
    var shop = cart.ShopProductsSlice;
    var catertogo = cart.CaterToGoProductsSlice;
    // console.log(shop, catertogo);
    shop.concat(...catertogo);

    var product_ids = shop.map((data: IProduct) => {
      return { id: data.id, count: data.quantity };
    });

    var data = {
      ...values,
      product_ids: product_ids,
      deliveryoption_id: deliveryOption?.id,
    };

    console.log(data);

    storeOrder(data)
      .then(response => {
        toast.info('Order Placed');
        store.dispatch(clearCart());
        store.dispatch(clearShopCart());
        setOrder(response?.data?.data);
        setSubmitted(true)
      })
      .catch(() =>
        toast.error(
          'Error submitting your order. contact us directly if this continues)'
        )
      );
  }

  useEffect(() => {
    let tmpsub = 0;

    getDeliveryOptions()
      .then(response => {
        setDeliveryOptions(response?.data?.data);
      })
      .catch(() => {
        toast.error('Error fetching delivery options');
      });
    cart.ShopProductsSlice.forEach((product: IProduct) => {
      tmpsub += product.price * product.quantity;
    });
    cart.CaterToGoProductsSlice.forEach((product: IProduct) => {
      tmpsub += product.price * product.quantity;
    });

    setSubTotal(tmpsub);
  }, [cart]);

  return (
    <Page title="Amazing Catering in Harare">
      <Box>
        <Header
          title="Shopping Cart"
          subtitle="Finish Placing your order here and we will fulfill it in no time"
          backgroundImage={getBannerImageByName('cartpagebanner', banners)}
        />
        {/* <pre>{JSON.stringify(cart, null, 1)}</pre> */}
        <pre>{/* {JSON.stringify(products,null,1)} */}</pre>
        {cart.ShopProductsSlice.length === 0 &&
          cart.CaterToGoProductsSlice.length === 0 && !submitted &&(
            <Box py={{ base: '5', md: '10' }}>
              <Flex
                w={{ base: '100%', md: '60%' }}
                px="2"
                mx="auto"
                flexWrap="wrap"
              >
                <Box w="100%">
                  <Heading
                    fontFamily="Playfair Display"
                    fontWeight="200"
                    fontSize={{ base: '3xl', md: '5xl' }}
                    display="block"
                    my={{ base: '5', md: '10' }}
                    w="100%"
                    textAlign="center"
                  >
                    You Cart is Empty
                  </Heading>
                </Box>
                <Box w={{ base: '100%', md: '50%' }} px="2" py="2">
                  <Box
                    p="4"
                    background="gray.200"
                    shadow="md"
                    _hover={{
                      shadow: 'large',
                    }}
                  >
                    <FadeCard
                      image={getBannerImageByName('shoppagebanner', banners)}
                    >
                      <Box p="4">
                        <Heading
                          mt="-20"
                          fontFamily="playfair"
                          fontSize="3xl"
                          px="4"
                          fontWeight="500"
                          color="gray.900"
                          textAlign="center"
                        >
                          Shop
                        </Heading>
                        <Box>
                          <Divider
                            variant="solid"
                            borderColor="gray.500"
                            my="4"
                            w="100px"
                            mx="auto"
                          />
                        </Box>

                        <Text
                          color="gray.900"
                          textAlign="center"
                          fontFamily="playfair"
                          my="10"
                        >
                          Spice up your Cooking with our authentic flavours
                        </Text>
                        <Link to="/shop">
                          <Button
                            w="full"
                            borderRadius="0"
                            colorScheme="red"
                            mb="5"
                            size="lg"
                          >
                            Browse Shop
                          </Button>
                        </Link>
                      </Box>
                    </FadeCard>
                  </Box>
                </Box>
                <Box w={{ base: '100%', md: '50%' }} px="2" py="2">
                  <Box
                    p="4"
                    background="gray.200"
                    shadow="md"
                    _hover={{
                      shadow: 'large',
                    }}
                  >
                    <FadeCard
                      image={getBannerImageByName(
                        'catertogopagebanner',
                        banners
                      )}
                    >
                      <Box p="4">
                        <Heading
                          mt="-20"
                          fontFamily="playfair"
                          fontSize="3xl"
                          px="4"
                          fontWeight="500"
                          color="gray.900"
                          textAlign="center"
                        >
                          Cater to Go
                        </Heading>
                        <Box>
                          <Divider
                            variant="solid"
                            borderColor="gray.500"
                            my="4"
                            w="100px"
                            mx="auto"
                          />
                        </Box>

                        <Text
                          color="gray.900"
                          textAlign="center"
                          fontFamily="playfair"
                          my="10"
                        >
                          Experience our flavours in the comfort of your home
                        </Text>
                        <Link to="/cater-to-go">
                          <Button
                            w="full"
                            borderRadius="0"
                            colorScheme="red"
                            mb="5"
                            size="lg"
                          >
                            Browse Shop
                          </Button>
                        </Link>
                      </Box>
                    </FadeCard>
                  </Box>
                </Box>
              </Flex>
            </Box>
          )}
        {((cart.ShopProductsSlice.length !== 0 ||
          cart.CaterToGoProductsSlice.length !== 0) || submitted) && (
          <Box>
            <Box w="" mx={{ base: '1', md: '1' }} mt="10">
              <Flex w="" justify="center" flexWrap="wrap">
                <Box w={{ base: '100%', md: '60%' }} px="2">
                  <Heading
                    fontFamily="Playfair Display"
                    fontWeight="200"
                    fontSize="5xl"
                    display="block"
                    w="100%"
                  >
                    Shopping Cart
                  </Heading>
                  <Box w="100%" mt="10">
                    {cart.ShopProductsSlice.map((product: IProduct, i: any) => {
                      return (
                        <CartItem product={product} type={'store'} key={i} />
                      );
                    })}
                  </Box>
                  {/* <Divider variant="solid" borderColor="gray.500" my="4" /> */}
                  <Box w="100%">
                    {cart.CaterToGoProductsSlice.map(
                      (product: IProduct, i: any) => {
                        return (
                          <CartItem
                            product={product}
                            type={'catertogo'}
                            key={i}
                          />
                        );
                      }
                    )}
                  </Box>
                  <Text
                    color="gray.400"
                    mt="4"
                    _hover={{
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    }}
                    display="inline-block"
                    onClick={e => {
                      store.dispatch(clearCart());
                      store.dispatch(clearShopCart());
                    }}
                  >
                    Clear Cart
                  </Text>
                  <Flex>
                    <Spacer />
                    <Box>
                      <Text
                        fontSize=""
                        color="gray.400"
                        display="inline-block"
                        fontWeight="900"
                      >
                        Subtotal
                      </Text>
                      <Text display="inline-block" ml="4" fontSize="">
                        ${subtotal}
                      </Text>
                    </Box>
                  </Flex>
                  {deliveryOption && (
                    <Flex>
                      <Spacer />
                      <Box>
                        <Text
                          fontSize=""
                          color="gray.400"
                          display="inline-block"
                          fontWeight="900"
                        >
                          Delivery
                        </Text>
                        <Text display="inline-block" ml="4" fontSize="">
                          {' '}
                          ${deliveryOption?.price}
                        </Text>
                      </Box>
                    </Flex>
                  )}
                  <Flex>
                    <Spacer />
                    <Box>
                      <Text
                        fontSize=""
                        color="gray.400"
                        display="inline-block"
                        fontWeight="900"
                      >
                        Order Total
                      </Text>
                      <Text display="inline-block" ml="4" fontSize="">
                        {' '}
                        ${(deliveryOption?.price ?? 0) + subtotal}
                      </Text>
                    </Box>
                    <Box></Box>
                  </Flex>
                </Box>
                <Box w={{ base: '100%', md: '30%' }}>
                  <Box
                    m="2"
                    color="gray.900"
                    backgroundColor="gray.200"
                    shadow="lg"
                    p="4"
                    position="sticky"
                    top="20"
                  >
                    {!submitted && (
                      <Box backgroundColor="white" p="4">
                        <Heading
                          fontFamily="playfair"
                          fontSize="3xl"
                          fontWeight="500"
                        >
                          Your Details
                        </Heading>
                        <pre>{JSON.stringify(order, null, 1)}</pre>
                        <Divider
                          variant="solid"
                          borderColor="gray.500"
                          my="4"
                        />
                        <Box>
                          <Formik
                            initialValues={initialValues}
                            validationSchema={FormSchema}
                            onSubmit={values => {
                              submit(values);
                            }}
                          >
                            {({ isSubmitting, values }) => (
                              <Form>
                                {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                                <Field name="name">
                                  {({
                                    field,
                                    form,
                                  }: {
                                    field: any;
                                    form: any;
                                  }) => (
                                    <FormControl
                                      isInvalid={
                                        form.errors.name && form.touched.name
                                      }
                                      isRequired
                                      mt="8"
                                    >
                                      <FormLabel>Name</FormLabel>
                                      <Input
                                        borderRadius="0"
                                        {...field}
                                        type="text"
                                        placeholder="name"
                                        _autofill={{
                                          transition:
                                            'all 5000s ease-in-out 0s',
                                        }}
                                      />
                                      <FormErrorMessage>
                                        {form.errors.name}
                                      </FormErrorMessage>
                                    </FormControl>
                                  )}
                                </Field>

                                <Field name="phone">
                                  {({
                                    field,
                                    form,
                                  }: {
                                    field: any;
                                    form: any;
                                  }) => (
                                    <FormControl
                                      isInvalid={
                                        form.errors.phone && form.touched.phone
                                      }
                                      isRequired
                                      mt="8"
                                    >
                                      <FormLabel>Phone</FormLabel>
                                      <Input
                                        borderRadius="0"
                                        {...field}
                                        type="text"
                                        placeholder="phone"
                                        _autofill={{
                                          transition:
                                            'all 5000s ease-in-out 0s',
                                        }}
                                      />
                                      <FormErrorMessage>
                                        {form.errors.phone}
                                      </FormErrorMessage>
                                    </FormControl>
                                  )}
                                </Field>

                                <Field name="whatsapp">
                                  {({
                                    field,
                                    form,
                                  }: {
                                    field: any;
                                    form: any;
                                  }) => (
                                    <FormControl
                                      isInvalid={
                                        form.errors.whatsapp &&
                                        form.touched.whatsapp
                                      }
                                      isRequired
                                      mt="8"
                                    >
                                      <FormLabel>Whatsapp number</FormLabel>
                                      <Input
                                        borderRadius="0"
                                        {...field}
                                        type="text"
                                        placeholder="whatsapp"
                                        _autofill={{
                                          transition:
                                            'all 5000s ease-in-out 0s',
                                        }}
                                      />
                                      <FormErrorMessage>
                                        {form.errors.name}
                                      </FormErrorMessage>
                                    </FormControl>
                                  )}
                                </Field>

                                <Field name="deliveryoption_id">
                                  {({
                                    field,
                                    form,
                                  }: {
                                    field: any;
                                    form: any;
                                  }) => (
                                    <FormControl
                                      isInvalid={
                                        form.errors.deliveryoption_id &&
                                        form.touched.deliveryoption_id
                                      }
                                      isRequired
                                      mt="8"
                                    >
                                      <FormLabel>Delivery Option</FormLabel>
                                      <Select
                                        {...field}
                                        placeholder="Select Delivery Option"
                                        isRequired
                                        onChange={e => {
                                          setDeliveryOption(
                                            deliveryOptions
                                              ?.filter(opt => {
                                                return (
                                                  opt.id ===
                                                  parseInt(e.target.value)
                                                );
                                              })
                                              .shift()
                                          );
                                        }}
                                      >
                                        {deliveryOptions?.map(item => {
                                          return (
                                            <option
                                              value={item.id}
                                              key={item.id}
                                            >
                                              {item.name}
                                            </option>
                                          );
                                        })}
                                      </Select>
                                      <FormErrorMessage>
                                        {form.errors.deliveryoption_id}
                                      </FormErrorMessage>
                                    </FormControl>
                                  )}
                                </Field>

                                <Field name="address">
                                  {({
                                    field,
                                    form,
                                  }: {
                                    field: any;
                                    form: any;
                                  }) => (
                                    <FormControl
                                      style={{
                                        display: deliveryOption ? '' : 'none',
                                      }}
                                      isInvalid={
                                        form.errors.address &&
                                        form.touched.address
                                      }
                                      isRequired
                                      mt="8"
                                    >
                                      <FormLabel>Address</FormLabel>
                                      <Input
                                        borderRadius="0"
                                        {...field}
                                        type="text"
                                        placeholder="address"
                                        _autofill={{
                                          transition:
                                            'all 5000s ease-in-out 0s',
                                        }}
                                      />
                                      <FormErrorMessage>
                                        {form.errors.name}
                                      </FormErrorMessage>
                                    </FormControl>
                                  )}
                                </Field>

                                <PaymentDetails />
                                <Button
                                  colorScheme="blue"
                                  // w="full"
                                  mt="10"
                                  w="full"
                                  borderRadius="0"
                                  type="submit"
                                  isLoading={loading}
                                  loadingText="Submitting"
                                >
                                  Submit
                                </Button>
                              </Form>
                            )}
                          </Formik>
                        </Box>
                      </Box>
                    )}

                    {submitted && (
                      <Box backgroundColor="white" p="4">
                        <Heading
                          fontFamily="playfair"
                          fontSize="3xl"
                          fontWeight="500"
                        >
                          Your Order Details
                        </Heading>
                        <Divider
                          variant="solid"
                          borderColor="gray.500"
                          my="4"
                        />
                        <Box>
                          <Heading size="sm">Thank you for your Order</Heading>
                          <Text>Your Order ID is C4U{pad(order?.id, 4)}</Text>
                          <Text>Your Order total is ${order.total}</Text>
                          <PaymentDetails />

                          <Heading mt="4" fontWeight="bold" size="sm">
                            The order will be
                          </Heading>

                          {!order.address && (
                            <Text>
                              Collected from 23 – 6th Avenue Mabelreign<br></br>
                              Harare Zimbabwe. If you pay remotely we will
                              contact you as soon as it's ready for pickup
                            </Text>
                          )}
                          {order.address && (
                            <Text>Delivered to : {order.address}</Text>
                          )}
                          {order.address && (
                            <Text>In about : {order?.deliveryoption?.ETA} minutes</Text>
                          )}
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Flex>
            </Box>
            <Box
              mt="20"
              style={{
                background: `url(${threetotwo})`,
              }}
              backgroundSize="cover"
              bgRepeat="no-repeat"
            >
              <Flex opacity=".8">
                <Spacer></Spacer>
                <Box
                  w={{ base: '100%', md: '50%' }}
                  my="10"
                  bg="white"
                  color="gray.900"
                  px="10"
                  py="10"
                >
                  <Heading
                    fontFamily="Playfair Display"
                    fontWeight="200"
                    fontSize="5xl"
                  >
                    Lovingly Made <br /> Perfectly Delivered
                  </Heading>
                  <Text mt="6">
                    Cater4u was born out of a passionate love for food - the
                    ingredients; the aroma and flavours; the flair and
                    creativity of presentation and the absolute joy and warmth
                    that food brings to any gathering.
                  </Text>
                  <Text mt="6">
                    At Cater4u, we are so passionate about our food that we hold
                    ourselves to exceptionally high standards that focus on
                    giving you individualised flawless service at every touch
                    point - we CATER4U!
                  </Text>
                  <Text mt="6">
                    For us, good food evokes warm feelings of family, love, joy
                    and celebration, and out of this love for creating joyful
                    memories and lasting impressions, we have an unwavering
                    commitment to personalised and excellent customer service.
                  </Text>
                </Box>
              </Flex>
            </Box>
          </Box>
        )}
      </Box>
    </Page>
  );
};

const PaymentDetails = () => {
  return (
    <Box>
      <Heading fontSize="md" mt="4">
        Payment Options
      </Heading>
      <List spacing={0}>
        <ListItem>
          <ListIcon as={RiMoneyDollarCircleFill} color="green.500" />
          Cash on Pickup
        </ListItem>
        <ListItem>
          <ListIcon as={FiCreditCard} color="green.500" />
          Bank transfer
        </ListItem>
      </List>
    </Box>
  );
};
