import * as React from 'react';
import { Box } from '@chakra-ui/react';
import { Helmet } from 'react-helmet-async';

interface ComponentProps {
  children: React.ReactChild | React.ReactChildren;
  title: string
}

export const Page = ({ children, title = '', ...rest }:ComponentProps) => {
  return (
    <Box {...rest}>
      <Helmet>
        <title>{process.env.REACT_APP_SITE_NAME + ' | ' + title}</title>
      </Helmet>
      {children}
    </Box>
  );
};
